import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Button, Card } from 'antd';
import logo from "./../assets/alert.png";
import success from "./../assets/Rectangle.png";

const SuccessTransaction = () => {
    const [storeReceiptID, setStoreReceiptID] = useState(null);
    const [storeReceiptNumber, setStoreReceiptNumber] = useState(null);

    const location = useLocation();

    useEffect(() => {
        const ReceiptId = location.state && location.state.ReceiptId;
        const ReceiptNumber = location.state && location.state.ReceiptNumber;
        setStoreReceiptID(ReceiptId);
        setStoreReceiptNumber(ReceiptNumber);
    }, []);

    
    console.warn('ReceiptId', storeReceiptID)
    console.warn('ReceiptNumber', storeReceiptNumber)

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#EBF1FA",
        }}>
            <Card style={{ width: '370px', textAlign: 'center' }}>
                <div>
                    <img src={success} alt="logo" style={{ width: '50%', margin: 'auto'}} />
                    <br />
                    <div>Success</div>
                    {/* <br /> */}
                    {/* </br> */}
                    <div><b>ReceiptId: </b>{storeReceiptID}</div>
                    {/* <br />
                    <div><b>ReceiptNumber: </b>{storeReceiptNumber}</div> */}
                </div>
            </Card>
        </div>
    );
};

export default SuccessTransaction;
