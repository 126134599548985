import React, { useState, useEffect, useContext } from "react";
import { Card, Radio, Tabs, Modal, Form, Select, Input } from "antd";
import { Option } from "antd/lib/mentions";
import "./Tab.css";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/leads";
import { checkAgent, stoageGetter } from "../../helpers";
import { Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import LeadCard from "../LeadCards/LeadCard";
import GlobalFilters from "./Filter";
import AllocateModalShow from "./Allocate";
import person_black from "./../Activitity Tracker/icons/person_black.png";
import person_white from "./../Activitity Tracker/icons/person_white.png";
import group_white from "./../Activitity Tracker/icons/group_white.png";
import group_black from "./../Activitity Tracker/icons/group_black.png";
import { useLocation } from "react-router-dom";
import axiosRequest from "./../../axios-request/request.methods";
import { PlusOutlined } from "@ant-design/icons";
// import 'bootstrap/dist/css/bootstrap.min.css';

// api's
import {
  getTeamMainTabApi,
  getFirstDropdownValueApi,
  getSecondDropdownValueApi,
  getFormByIdApi,
  getOpenTabApi,
  getFortodayTabApi,
  getFailedTabApi,
} from "../actions/allleadAction";
import { flexibleCompare } from "fullcalendar";
import { Column } from "@antv/g2plot";

const { TabPane } = Tabs;

const Tab = ({
  tabMenu,
  header,
  detailsRouteTab,
  activeKey,
  activeRenewalkey,
  current,
  filterdata,
  resetDataFields,
}) => {
  const currentLocation = useLocation();
  // console.log('************************ current ___ *********************===========>>>',current)

  const dispatch = useDispatch();
  const { leadType } = useParams();
  const { masterType } = useParams();
  const [activeTab, setactiveTab] = useState();
  const [showTab, setShowTab] = useState();
  const [showModal, setShowModal] = useState(false);
  const [leadTabFilter, setLeadTabFilter] = useState("all");

  let history = useHistory();
  let _currentTab = "self";
  // const [activeKey, setActiveKey] = useState("self")
  const [currentActiveTab, setCurrentActiveTab] = useState("self");
  const [count, setCount] = useState([]);

  useEffect(() => {
    try {
      let res = axiosRequest.get(
        `user/v2/getleads_team_count/6389f8ba28144899934032de`,
        { secure: true }
      );
      console.log("res 123123123 = ", res);
      // let res = {
      //   errCode: -1,
      //   errMsg: {
      //     converted: 0,
      //     open_lead: 89,
      //     all_lead: 100,
      //     failed: 0,
      //     today: 2,
      //   },
      //   dbDate:
      //     "Thu Dec 15 2022 11:03:37 GMT+0000 (Coordinated Universal Time)",
      // };
      count.push(res?.errMsg?.all_lead);
      count.push(res?.errMsg?.today);
      count.push(res?.errMsg?.open_lead);
      count.push(res?.errMsg?.converted);
      count.push(res?.errMsg?.failed);
    } catch (error) {
      console.log(error);
    }
  }, [currentActiveTab]);

  useEffect(() => {
    console.log(
      "************************ leadTabFilter leadTabFilter *********************===========>>>",
      leadTabFilter
    );
    // getDataForOpen(leadTabFilter);
    if (header === "Lead") getDataForOpen(leadTabFilter);
  }, [current]);

  // useEffect(() => {

  //   if (header === "Lead") getDataForOpen("all");
  // }, []);

  // ************************Api *********************

  const getDataForOpen = async (leadInc) => {
    setLeadTabFilter(leadInc);
    // let leadtyp = leadInc;
    const { id } = stoageGetter("user");
    // console.log('************************ current ___*(*(*((**)))) *********************===========>>>',current)
    let _pageNo = current === undefined || current === null ? 1 : current;
    if (_currentTab === "self") {
      dispatch(actions.fetchAllLeads(id, leadInc, _pageNo));
    } else {
      const teamId = stoageGetter("teamMemberId");
      console.warn("teamId______===========>>>", teamId);
      dispatch(
        actions.fetchAllLeads(
          teamId === null || teamId === undefined ? id : teamId,
          leadInc,
          _pageNo
        )
      );
    }
  };

  const handler = (activeKey) => {
    console.log("activeKey------------->>>>>>>>", activeKey);
    setactiveTab(activeKey);
    // dispatch(actions.fetchAllLeads(activeTab,current))

    // setactiveKey(key)
    if (activeKey) {
      switch (activeKey) {
        case "all": {
          getDataForOpen("all");
          return history.push("/leadMaster/all_leads");
        }
        case "fortoday": {
          getDataForOpen("fortoday");
          return history.push("/leadMaster/fortoday");
        }
        case "open": {
          getDataForOpen("open");
          return history.push("/leadMaster/openlead");
        }
        case "converted": {
          getDataForOpen("converted");
          return history.push("/leadMaster/convertedleads");
        }
        case "failed": {
          getDataForOpen("failed");
          return history.push("/leadMaster/pendingproposal");
        }

        case "1":
          return history.push("/leadmasterpage/statuslead");
        case "2":
          return history.push("/company-intelligence");
        // case '3':
        //   return history.push('/leadmasterpage/proposal')
        // case '4':
        //   return history.push('/leadmasterpage/leadmasterdoc/leaddoc')
        case "3":
          return history.push("/leadmasterpage/leadhistory");

        case "calendar":
          return history.push("/calendar");

        case "todo":
          return history.push("/todo");

        default:
          return history.push("/home");
      }
    }
  };

  let tabPane = [];
  if (tabMenu && !_.isEmpty(tabMenu)) {
    tabPane = _.map(tabMenu, (value, id) => {
      return (
        <TabPane
          key={value.id}
          tab={value.value + "(" + count[id] + ")"}
        ></TabPane>
      );
    });
    // console.warn("tabPane", tabPane)
  }

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [width]);

  const handleChangeTab = (currentTab) => {
    // console.log("good bye ",currentTab)
    console.log("good bye currentActiveTab", currentActiveTab);
    _currentTab = currentTab;
    setCurrentActiveTab(currentTab);
    getDataForOpen("all");
    dispatch(actions.updateTabOfDashboard(currentTab));

    // if (currentTab === "team") getDataForOpen();
    currentTab !== currentActiveTab &&
      dispatch(actions.updateAllocateOfOpportunities(false));
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addNewOpportunity = () => {
    resetDataFields();
  };

  return (
    <>
      {width > breakpoint ? (
        // className="header-img-tabs header-img-height tabsStyle"
        <div
          className={
            header === "Lead"
              ? "header-img-tabs header-img-height tabsStyle"
              : "header-img-tabs tabsStyle"
          }
        >
          <div>
            <div>
              <p className="header-title-tab">{header} </p>
            </div>

            <div>
              <Tabs
                tabBarGutter={20}
                centered={false}
                type="card"
                onTabClick={handler}
                size="small"
                activeKey={activeKey}
                className="main-lead-tabs"
                style={{ marginLeft: "40px" }}
              >
                {tabPane}
              </Tabs>
            </div>
          </div>

          {header !== "Lead" && activeKey === "1" && (
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginBottom: 15,
              }}
            >
              <Button
                onClick={() => addNewOpportunity()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 5,
                  backgroundColor: "#00ACC1",
                  border: "transparent",
                  color: "#fff",
                }}
                size="large"
              >
                <PlusOutlined style={{ fontSize: 16, marginRight: 10 }} /> Add
                New Opportunity
              </Button>
            </div>
          )}
          {/* header === "Lead" */}
          {/* <div style={{ display: "flex" }}>
            {tabPane.key === activeKey ? (
              <div className="round-card-main-Tab">
                {checkAgent() === false && (
                  <>
                    <figure
                      className={
                        currentActiveTab === "team"
                          ? "round-cards1-active"
                          : "round-cards1"
                      }
                      onClick={() => handleChangeTab("team")}
                      key={"team"}
                    >
                      {" "}
                      <figcaption className="card-caption">
                        Team
                      </figcaption>{" "}
                    </figure>
                    <figure
                      className={
                        currentActiveTab === "self"
                          ? "round-cards2-active"
                          : "round-cards2"
                      }
                      onClick={() => handleChangeTab("self")}
                      key={"self"}
                    >
                      {" "}
                      <figcaption className="card-caption">
                        Self
                      </figcaption>{" "}
                    </figure>
                  </>
                )}
                <AllocateModalShow tabSelected={leadTabFilter} />
                <GlobalFilters
                  show={show}
                  onHide={handleClose}
                  handleShow={handleShow}
                  setShow={setShow}
                  tabFilter={leadTabFilter}
                />
              </div>
            ) : null}
          </div> */}
        </div>
      ) : (
        // FOR MOBILE WEB
        <div
          className="tabsStyle"
          style={{ display: "flex", flexDirection: "Column" }}
        >
          <div>
            <Tabs
              tabBarGutter={20}
              centered={false}
              onTabClick={handler}
              size="small"
              activeKey={activeKey}
              style={{
                backgroundColor: "#red",
                boxShadow: "0px 1px 10px 0px #0000003d",
              }}
            >
              {tabPane}
            </Tabs>
          </div>
          {/* {header === "Lead" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "16px",
              }}
            >
              {checkAgent() === false && (
                <>
                  <button
                    onClick={() => handleChangeTab("self")}
                    key={"self"}
                    className={
                      currentActiveTab === "self"
                        ? "active_tabs_button"
                        : "tabs_button"
                    }
                  >
                    <img
                      src={
                        currentActiveTab === "self"
                          ? person_black
                          : person_white
                      }
                      className="person"
                      alt="person_png"
                    />{" "}
                    Self
                  </button>
                  <button
                    onClick={() => handleChangeTab("team")}
                    key={"team"}
                    className={
                      currentActiveTab === "team"
                        ? "active_tabs_button"
                        : "tabs_button"
                    }
                  >
                    <img
                      src={
                        currentActiveTab === "team" ? group_white : group_black
                      }
                      className="group"
                      alt="person_png"
                    />{" "}
                    Team
                  </button>
                </>
              )}
              <AllocateModalShow tabSelected={leadTabFilter} />
              <GlobalFilters
                show={show}
                onHide={handleClose}
                handleShow={handleShow}
                setShow={setShow}
                tabFilter={leadTabFilter}
              />
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default Tab;
