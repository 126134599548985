const NODE_ENV = 'prod';
// const NODE_ENV = 'uat';
// const NODE_ENV = 'dev';

const DEV_BASE_URL = 'https://etlinode.salesdrive.app/';
const UAT_BASE_URL = 'https://etlinode-preprod.salesdrive.app/'; 
const PROD_BASE_URL = 'https://etliwebprodnode.salesdrive.app/';
// const PROD_BASE_URL = 'https://apis-preprod.myzindagi.edelweisstokio.in/';
// const PROD_BASE_URL = 'https://apis-preprod.myzindagi.edelweisstokio.in/';

const _config = {
  baseURL: NODE_ENV === 'dev' ? DEV_BASE_URL : NODE_ENV === 'uat' ? UAT_BASE_URL : PROD_BASE_URL,
  // baseURL: NODE_ENV === 'development' ? DEV_BASE_URL : PROD_BASE_URL,
  // baseURL: UAT_BASE_URL,
  auth: 'auth/',
  secure: 'secure/',
  NODE_ENV: NODE_ENV
}

export default _config;